import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"


export default function connectXero () {
    return (
        <Layout>
            <SEO
                title="QuickBooks Disconnect"
                description="Disconnect quickbooks from taxomate"
                image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
                pathname='/quickbooks-disconnect/'
                originalPathname='/quickbooks-disconnect/'
            />

              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 inline-flex justify-center items-center flex-wrap mt-7 text-gray-600 w-9/12">
                  <h1 className="tracking-tight font-extrabold text-4xl sm:text-5xl lg:text-6xl text-center mb-6">
                  QuickBooks Disconnected
                    </h1>
                    <p>
                        Your QuickBooks integration has been disconnected. You will no longer be able to send Invoices or Journals to QuickBooks from taxomate.
                    </p>
              </div>

              <div className="w-full justify-center flex py-12 px-4">
        <div className="px-8 py-8 bg-blue-600 dark:bg-gray-800 rounded-xl max-w-lg">
            <p className="text-2xl lg:text-3xl font-bold lg:leading-10 text-white dark:text-gray-50">
                Reconnect taxomate and QuickBooks
            </p>
            <ul className="list-decimal px-6 py-6 text-white mb-4">
                <li>Login to your taxomate account.</li>
                <li>From the side menu select Settings and then Connections.</li>
                <li>Click on Connect to QuickBooks to reconnect.</li>
            </ul>
            <a href="https://app.taxomate.com/sign-in" className="transition rounded border mt-2 mx-2 my-2 px-8 py-3 text-indigo-700 hover:text-indigo-600 text-sm bg-white border-indigo-700 hover:border-indigo-600 duration-150 ease-in-out">Sign In</a>
        </div>
    </div>


  </Layout>
    );
}
